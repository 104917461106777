ngx-duration-picker {
  table {
    th, td {
      padding: 0 5px;
    }

    tr {
      td {
        a {
          position: relative !important;
          overflow: hidden !important;
          max-height: 10px !important;

          .bs-chevron {
            position: absolute !important;
            left: -3px !important;

            &-up {
              top: 8px !important;
            }

            &-down {
              top: 8px !important;
            }
          }
        }
      }
    }
  }
}
