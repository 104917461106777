@use "typos" as *;

$font-path: '/assets/fonts/';

// PRIMARY FONT
@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url($font-path + $primary-font + '-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $primary-font + ' Light';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url($font-path + $primary-font + '-Light.ttf') format('truetype');
}

@font-face {
  font-family: $primary-font;
  font-style: italic;
  src: url($font-path + $primary-font + '-Italic.ttf') format('truetype');
}

@font-face {
  font-family: $primary-font + ' Medium';
  font-weight: $font-weight-medium;
  src: url($font-path + $primary-font + '-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $primary-font + ' Semibold';
  font-weight: $font-weight-semibold;
  src: url($font-path + $primary-font + '-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $primary-font + ' Bold';
  font-weight: $font-weight-bold;
  src: url($font-path + $primary-font + '-Bold.ttf') format('truetype');
}

// SECONDARY FONT
@font-face {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url($font-path + $secondary-font + '-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $secondary-font + ' Light';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url($font-path + $secondary-font + '-Light.ttf') format('truetype');
}

@font-face {
  font-family: $secondary-font;
  font-style: italic;
  src: url($font-path + $secondary-font + '-Italic.ttf') format('truetype');
}

@font-face {
  font-family: $secondary-font + ' Medium';
  font-weight: $font-weight-medium;
  src: url($font-path + $secondary-font + '-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $secondary-font + ' Semibold';
  font-weight: $font-weight-semibold;
  src: url($font-path + $secondary-font + '-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $secondary-font + ' Bold';
  font-weight: $font-weight-bold;
  src: url($font-path + $secondary-font + '-Bold.ttf') format('truetype');
}

