@use "@angular/material" as mat;
@import "variables";

mat-button, .mat-button, mat-radio, .mat-radio {
  &-disabled {
    cursor: not-allowed !important;
  }
}

//RADIO BUTTON
.group-selection {
  &-outline {
    .select-option {
      width: 32rem;
      min-height: 5.2rem;
      border: 1px solid $border-color;
      margin-right: .8rem;
      background-color: $border-color;

      &:last-child {
        margin-right: unset;
      }

      &.mat-radio-checked {
        background-color: $white;
        border-width: .2rem;
        border-color: mat.get-color-from-palette($primary-palette, 500);
      }

      .mat-radio-label {
        padding: 1.2rem;

        .mat-radio-label-content {
          width: 100%;
          word-break: break-word;
          white-space: break-spaces;
        }
      }
    }
  }
}
