@use "@angular/material" as mat;
@import "variables";

.mat-form-field {
  &.mat-form-field-appearance-outline {
    & .mat-form-field-outline-thick {
      color: mat.get-color-from-palette($primary-palette, 500);
    }
  }

  &-ripple {
    background-color: mat.get-color-from-palette($secondary-palette, 400);
  }

  &-wrapper {
    // margin-bottom: -0.8em !important;
  }

  &-underline {
    position: relative !important;
    bottom: auto !important;

    &:hover {
      background-color: red;
    }
  }

  // &-subscript-wrapper {
  //   position: static !important;
  // }

  &-disabled {
    cursor: not-allowed;

    .mat-form-field {
      &-outline {
        border-radius: 0.5rem;
        background-color: $background-disabled;
      }
    }
  }

  &-label-wrapper {
    .mat-form-field {
      &-required-marker {
        color: $error-text;
      }
    }
  }

  &-clear-suffix-spacing {
    .mat-form-field {
      &-suffix {
        top: unset !important;
      }
    }
  }

  &-reset-padding {
    .mat-form-field-wrapper {
      margin: 0 !important;
      padding: 0 !important;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.mat-form-field {
  mat-select,
  .mat-select {
    &-no-label {
      & .mat-select-arrow-wrapper {
        transform: unset;
      }
    }
  }

  &-selection-combine {
    .mat-form-field {
      &-suffix {
        top: unset !important;

        mat-select,
        .mat-select {
          &-arrow-wrapper {
            display: none !important;
          }

          &-value {
            font-family: $primary-font-medium;
            font-weight: $font-weight-medium;
            color: $light-gray-text;
          }
        }
      }
    }
  }

  &-mini-selection {

    width: 10rem;

    & .mat-form-field-wrapper {
      margin: 0 !important;
      padding: 0 !important;

      & .mat-select-value {
        font-size: 1.4rem;
      }

    }
  }
}

