@use "../variables" as *;
@use "break-point-mixins" as *;

@mixin h1 {
  font-size: $h1-size;
  font-family: $secondary-font-bold;
  font-weight: $font-weight-bold;
}

@mixin h2 {
  font-size: $h2-size;
  font-family: $secondary-font-bold;
  font-weight: $font-weight-bold;
}

@mixin h3 {
  font-size: $h3-size;
  font-family: $secondary-font-semibold;
  font-weight: $font-weight-bold;
}

@mixin h4 {
  font-size: $h4-size;
  font-family: $secondary-font-semibold;
  font-weight: $font-weight-bold;
}

@mixin h5 {
  font-size: $h5-size;
  font-family: $secondary-font-semibold;
  font-weight: $font-weight-bold;
}

@mixin h6 {
  font-size: $h6-size;
  font-family: $secondary-font-semibold;
  font-weight: $font-weight-bold;
}

