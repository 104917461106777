@use "color-palette" as *;
@use "@angular/material" as mat;
//TEXT COLOR
$dark-blue-text: $secondary;
$light-blue-text: $primary;
$error-text: $red;
$success-text: $green;
$warning-text: $orange;
$body-background: #fafbfc;
$border-color: #0000001f;
$background-disabled: $disabled;
