//BECORPS THEME
$primary: #2a8bc4;
$secondary: #004d81;
$green: #03A66D;
$red: #F66045;
$red-light: #FDDAD3;
$orange: #E69C24;
$warning-light: #F7E4CA;
$grey: #E6E6E6;
$white: #FFFFFF;
$black: #000000;
$dark-gray: #3A4856;
$light-gray: #9EADBD;
$disabled: rgba(#ebebeb, 0.5);
//--------DEFAULT TEXT--------
$dark-gray-text: $dark-gray;
$light-gray-text: $light-gray;
$dark-primary-text: $black;
$light-primary-text: $white;
//--------STATUS--------
$status-default-background: rgba(229, 231, 235, 0.8);
$status-default-text: #929294;
$status-primary-background: #1d5b8e;
$status-new-background: #3498D8;
$status-new-text: $white;
$status-new-light-background: #eaf0fd;
$status-new-light-text: #346ff1;
$status-warning-background: rgba(242, 212, 170, 0.62);
$status-warning-text: #C8830C;
$status-success-background: #E6F9F0;
$status-success-text: $green;
$status-error-background: rgba(246, 96, 69, 0.2);
$status-error-text: $red;
$status-hold-background: rgba(229, 231, 235, 0.8);
$status-hold-text: #929294;
$status-cancelled-background: rgba(#5E6A72, 0.8);
$status-cancelled-text: $white;
$status-action-background: #34209E;
$status-action-text: $white;
$status-action-light-background: #e1ddff;
$status-action-light-text: #6a59ff;
$status-neutral-background: #f7f7f7;
$status-neutral-text: #3a3b51;

//--------Background--------
//PRIMARY PALETTE
//---------------------
$primary-palette: (
        50 : #e2f5fc,
        100 : #b6e6f7,
        200 : #89d6f2,
        300 : #60c5ec,
        400 : #47b9e9,
        500 : #37ace6,
        600 : #319ed7,
        700 : $primary,
        800 : #277bb0,
        900 : #1d5b8e,
        A100 : #BBDEFB,
        A200 : #90CAF9,
        A400 : #29B6F6,
        A700 : #005d93,
        contrast: (
                50: $dark-gray-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $dark-primary-text,
                500 : $light-primary-text,
                600 : $light-primary-text,
                700 : $light-primary-text,
                800 : $light-primary-text,
                900 : $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

//SECONDARY PALETTE
$secondary-palette: (
        50: #B6E6F7,
        100: #96D7F0,
        200: #79C6E8,
        300: #5DB4DD,
        400: #43A0D2,
        500: $secondary,
        600: #1F7CAE,
        700: #156C96,
        800: #0E5B7C,
        900: #084861,
        contrast: (
                50: $dark-primary-text,
                100:$dark-primary-text,
                200:$dark-primary-text,
                300:$light-primary-text,
                400:$light-primary-text,
                500:$light-primary-text,
                600:$light-primary-text,
                700:$light-primary-text,
                800:$light-primary-text,
                900:$light-primary-text,
        ),
);

//RED PALETTE
$red-palette: (
        50: #ffebee,
        100: #ffcdd2,
        200: #ef9a9a,
        300: #e57373,
        400: #ef5350,
        500: #f44336,
        600: #e53935,
        700: #d32f2f,
        800: #c62828,
        900: #b71c1c,
        contrast: (
                50: $dark-primary-text,
                100:$dark-primary-text,
                200:$dark-primary-text,
                300:$dark-primary-text,
                400:$dark-primary-text,
                500:$light-primary-text,
                600:$light-primary-text,
                700:$light-primary-text,
                800:$light-primary-text,
                900:$light-primary-text,
        )
);

//ORANGE PALETTE
$orange-palette: (
        50: #FFDBB3,
        100: #FFCC91,
        200: #FEBF73,
        300: #F8B356,
        400: #F0A73C,
        500: $orange,
        600: #CD8418,
        700: #B16B0F,
        800: #B16B0F,
        900: #935407,
        contrast: (
                50: $dark-primary-text,
                100:$dark-primary-text,
                200:$dark-primary-text,
                300:$dark-primary-text,
                400:$dark-primary-text,
                500:$light-primary-text,
                600:$light-primary-text,
                700:$light-primary-text,
                800:$light-primary-text,
                900:$light-primary-text,
        )
);

//GREEN PALETTE
$green-palette: (
        50: #B3EECE,
        100: #8FE3B9,
        200: #6BD7A6,
        300: #47C892,
        400: #24B880,
        500: $green,
        600: #00945C,
        700: #00814B,
        800: #006C3A,
        900: #00552B,
        contrast: (
                50: $dark-primary-text,
                100:$dark-primary-text,
                200:$dark-primary-text,
                300:$dark-primary-text,
                400:$dark-primary-text,
                500:$light-primary-text,
                600:$light-primary-text,
                700:$light-primary-text,
                800:$light-primary-text,
                900:$light-primary-text,
        )
);
$light-gray-palette: (
        50: #EBEFF2,
        100: #DBE3E8,
        200: #CCD6DE,
        300: #BCC8D3,
        400: #ADBBC8,
        500: $light-gray,
        600: #8E9CAB,
        700: #7E8C98,
        800: #6E7B85,
        900: #5E6A72,
        contrast: (
                50: $dark-primary-text,
                100:$dark-primary-text,
                200:$dark-primary-text,
                300:$dark-primary-text,
                400:$dark-primary-text,
                500:$light-primary-text,
                600:$light-primary-text,
                700:$light-primary-text,
                800:$light-primary-text,
                900:$light-primary-text,
        )
);



