mat-stepper {
  .mat-horizontal-stepper-wrapper {
    //align-items: center;

    .mat-horizontal-stepper-header-container {
      width: 90%;
      margin: auto auto .8rem auto;

      mat-step-header {
        //width: 18rem;
        max-width: 18rem;

        .mat-step-label {
          white-space: break-spaces;
        }

        .mat-stepper-horizontal-line {
          max-width: 1.6rem;
          margin: 0 0.4rem;
        }
      }
    }
  }
}
