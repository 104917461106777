@use "variables/colors" as *;
@use "variables/color-palette" as *;


.app-alert {
  background: white;
  color: $dark-gray;

  &.alert {
    &-default {
      background: #2269f6;
      color: $white;
    }

    &-error {
      background: #f04438;
      color: $white;
      //color: $status-error-text;
    }

    &-success {
      background: #13b76b;
      color: $white;
    }

    &-warning {
      background: #f79009;
      color: $white;
    }
  }
}

.primary-snackbar {
  background: white;
  color: $dark-gray;

  button {
    background-color: $light-blue-text;
    color: white !important;
  }
}

.warning-snackbar {
  background: $orange;
  color: white;

  button {
    background-color: $red;
    color: white !important;
  }
}

.error-snackbar {
  background: $red;
  color: white;

  button {
    background-color: $red;
    color: white !important;
  }
}
