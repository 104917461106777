@use '../utils' as *;
@use "@angular/material" as mat;
// Header
// --------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

// Body Text
// --------------------------------------------------
p {
  margin-top: 0;
  margin-bottom: 1.2rem;
}

small {
  font-size: 90%;
}

// List
// --------------------------------------------------
ul,
ol {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

ol ol,
ul ol {
  list-style-type: lower-roman;
}

ul ul ol,
ul ol ol,
ol ul ol,
ol ol ol {
  list-style-type: lower-alpha;
}

dd {
  margin-left: 0;
}

// Link
// --------------------------------------------------
a {
  color: $light-blue-text ;
  text-decoration: unset;
}
