// Font stacks
$opensans-font: 'OpenSans';
$montserrat: 'Montserrat';

$primary-font: $opensans-font;
$primary-font-light: $opensans-font + ' ' +'Light';
$primary-font-medium: $opensans-font + ' ' +'Medium';
$primary-font-semibold: $opensans-font + ' ' +'Semibold';
$primary-font-bold: $opensans-font + ' ' +'Bold';

$secondary-font: $montserrat;
$secondary-font-light: $montserrat + ' ' +'Light';
$secondary-font-medium: $montserrat + ' ' +'Medium';
$secondary-font-semibold: $montserrat + ' '+ 'Semibold';
$secondary-font-bold: $montserrat + ' '+ 'Bold';

$body-font: $primary-font, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !default;

// Font weights
$font-weight-normal: 400 !default;
$font-weight-light: 300 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

// Line heights
$lh-condensed-ultra: 1 !default;
$lh-condensed: 1.25 !default;
$lh-default: 1.5 !default;

// The base body size
$global-font-size: 62.5% !default;
$body-font-size: 1.6rem !default;
$body-font-mobile-size: 1.4rem !default;
$body-line-height: $lh-default !default;

//Heading size  - Scale 1.333
$h1-size: 4.8rem !default;
$h2-size: 4rem !default;
$h3-size: 3.2rem !default;
$h4-size: 2.8rem !default;
$h5-size: 2.4rem !default;
$h6-size: 2rem !default;
