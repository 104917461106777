@use '../utils' as *;

// Remove input background color by autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
//Text Area
textarea {
  @include scrollbar;
}
