@use '../utils' as *;
@use "variables";

mat-dialog-container, .mat-dialog-container {

  mat-dialog-content, .mat-dialog-content {
    @include scrollbar;

    //For custom mat-dialog
    .dialog-wrapper {

    }

    //CUSTOM EXTRA JOB DIALOG
    .extra-job-detail-wrapper {
      .billing-address {
        .address-card-wrapper {
          .mat-card {
            padding: 0;

            .billing-container {
              margin-bottom: 0.4rem;

              .billing-name, .billing-phone {
                font-family: variables.$primary-font-medium;
                font-weight: variables.$font-weight-medium;
                font-size: 1.4rem !important;
              }
            }

            .address-info {
              font-size: 1.4rem !important;
            }
          }
        }
      }
    }
  }

}
