mat-tab-group {
  .tab-content-wrapper {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  .mat-tab-header{
    .mat-tab-header-pagination{
      box-shadow: none;
    }
  }
}
