@use "../utils" as *;
@use "variables";
@use "@angular/material" as mat;

.becorps-wp {
  .cdk-overlay-container {
    //override cdk menu panel
    .mat-menu-panel {
      max-width: fit-content;
      max-height: calc(100vh - 68px);
      @include scrollbar;

      //MODIFY FOR NOTIFICATIONS
      & .notifications-container {
        max-height: 40%;

        & .notification-item {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid variables.$border-color;
          padding-bottom: .8rem;

          &:last-child {
            border: unset;
          }

          &:hover {
            background-color: mat.get-color-from-palette(variables.$light-gray-palette, 50);
          }

          &-icon {
            img {
              object-fit: cover;
              border: 1px solid rgba(variables.$primary, 0.5);
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }

          &-message {
          }

          &.unread {
            background-color: mat.get-color-from-palette(variables.$primary-palette, 50);

            &:hover {
              background-color: mat.get-color-from-palette(variables.$primary-palette, 100);
            }
          }
        }
      }
    }

    .mat-select-panel-wrap {
      .mat-select-panel {
        @include scrollbar;
      }
    }
  }
}
