@use "../variables";
// Scroll bar
@mixin scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #CCD6DE;
    //border-radius: 0.5rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #7E8C98;
    border-radius: 0.5rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #5E6A72;
  }
}
