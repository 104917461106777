@use '@angular/material' as mat;
@use '../utils' as *;

.bc-mat-sidenav {
  height: inherit;
  //MODIFY SIDEBAR SECTION
  .mat-drawer {
    overflow: unset;
    transform: none !important;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &-opened {
      width: 26rem;
      transform: none;
      display: block;
    }

    &-closed {
      width: 12rem;
      visibility: visible !important;
      display: block;
      transform: none;
    }

    &.sidebar-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: stretch;

      & .mat-drawer-inner-container {
        display: flex;
        flex-direction: column;
        overflow: unset !important;
      }
    }
  }

  .tenant-logo-wrapper {
    height: 7rem;
    max-height: 7rem;
    display: flex;
    padding: 1rem;
    justify-content: center;
    position: relative;
    text-align: center;

    & .img-wrapper {
      display: flex;
      width: 100%;
      height: 100%;

      // img {
      //   width: inherit;
      //   height: inherit;
      //   object-fit: contain;
      // }
    }

    & .collapse-btn {
      position: absolute;
      margin: auto 0;
      top: 0;
      bottom: 0;
      right: -1.4rem;
      width: 28px;
      height: 28px;
      padding: 0;
      background-color: #277bb0;
      color: $white;

      &:hover {
        background-color: #319ed7;
      }

      & .mat-button-wrapper {
        padding: 0;
        display: block;
      }
    }
  }

  .side-menu-wrapper {
    height: calc(100% - 7rem);
    overflow-y: auto;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include scrollbar;

    & .side-menu-inner {
      & .mat-subheader {
        height: 40px;
        padding: 0;
        margin: 0;
      }

      & .menu-item-wrapper {
        border-radius: 0.8rem;
      }
    }
  }

  .mat-drawer-closed {
    & .tenant-logo-wrapper {
      & .img-wrapper {
        width: 85%;
        margin: auto;

        img {
          object-fit: contain;
          width: 100%;
        }
      }
    }

    & .mat-drawer-inner-container {
      & .side-menu-wrapper {
        & .mat-subheader {
          display: none;
        }

        & .menu-item-wrapper {
          & .mat-list-item-content {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          & .mat-list-text {
            display: none;
            visibility: hidden;
          }
        }
      }
    }

  }

  .mat-drawer-opened {
    & .mat-drawer-inner-container {

    }

    & .tenant-logo-wrapper {
      img {
        width: 15rem !important;
        height: 5rem !important;
        object-fit: contain;
      }
    }

    & .side-menu-wrapper {
      padding: 0 1.6rem;
    }
  }

  .menu-item-wrapper {
    &.mat-list-item {
      &:hover {
        background-color: mat.get-color-from-palette($secondary-palette, 200);
        color: $white;
        font-family: $primary-font-medium;
        font-weight: $font-weight-medium;
        transition: all .3s ease;
      }

      &.active {
        background-color: mat.get-color-from-palette($secondary-palette, 400) !important;
        color: $white !important;
        font-family: $primary-font-medium !important;
        font-weight: $font-weight-medium !important;
      }
    }
  }

  //MODIFY CONTENT SECTION
  .mat-drawer-content {
    transition: margin-left 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    margin-left: 26rem !important;

    &-collapse {
      margin-left: 12rem !important;
    }

    &.content-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;

      & .app-content-wrapper {
        height: calc(100% - 7rem);
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar;
        padding: 2.4rem 2rem;
        background-color: $body-background;
      }
    }
  }
}
