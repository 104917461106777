@use "../utils" as *;
@import "flag-icons/css/flag-icons.min.css";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: $global-font-size;
}

body {
  overflow: hidden auto;
  height: $body-default-height;
  color: rgba($dark-primary-text, 0.8) !important;
  background-color: $body-background;
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $font-weight-normal;
  line-height: $body-line-height;
  @include respond-to("small") {
    font-size: $body-font-mobile-size;
  }

  .becorps-wp {
    height: 100%;
  }
}
