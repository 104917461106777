ngx-mat-intl-tel-input {
  .country-selector {
    //opacity:1 !important;
    background: none !important;
    width: 120px;
    bottom: 8px !important;
    .mat-button-focus-overlay {
      background-color:transparent;
    }
  }

  input:not(.country-search) {
    bottom: 3px;
    left: 10px;
  }

  .mat-menu-content:not(:empty) {
    max-height: 250px;
  }

  .country-list-button {
    font-size: 0.8rem !important;
  }
}
