@use "@angular/material" as mat;
@use '../utils' as *;
@use "variables";

.table-container {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  @include scrollbar;

  &-limited-scroll {
    min-height: 8.8rem;
    max-height: 32rem;
    overflow: auto;
    @include scrollbar
  }

  table {
    width: 100%;

    &.table-custom {
      width: 100%;
      table-layout: fixed;

      thead {
        .mat-header-row {
          .mat-header-cell {
            font-family: $secondary-font-medium;
            font-weight: $font-weight-medium;
            font-size: 1.5rem;
            color: rgba($dark-gray-text, 0.9);
            word-break: normal !important;
          }
        }
      }

      tbody {
        .mat-row {
          .mat-cell {
            font-size: 1.4rem;
            color: rgba(variables.$dark-gray-text, 0.87);
            word-break: break-word;
          }

          &.row-hover {
            cursor: pointer;

            &:hover {
              background-color: mat.get-color-from-palette(variables.$light-gray-palette, 50);
            }
          }

          &.add-indirect {
            td {
              border-bottom: none !important;
            }

            &-message {
              td {
                //border-bottom: none !important;
                padding: 0 !important;
              }
            }
          }
        }
      }

      th, td {
        padding: 1rem;
      }

      th {
        &.mat-header-cell:first-of-type {
          padding-left: 2.4rem;
        }

        &.mat-header-cell:last-of-type {
          padding-right: 2.4rem;

        }
      }

      td {
        &.mat-cell:first-of-type {
          //border-left: 0.5px solid variables.$border-color;
          padding-left: 2.4rem;
        }

        &.mat-cell:last-of-type {
          //border-right: 0.5px solid variables.$border-color;
          padding-right: 2.4rem;
        }
      }

      tr {
        &.mat-header-row {
          .mat-header-cell {
            font-family: variables.$secondary-font-medium;
            font-weight: variables.$font-weight-medium;
            font-size: 1.5rem;
            color: rgba(variables.$dark-gray-text, 0.9);
            word-break: break-word;
          }
        }

        &.mat-row {
          .mat-cell {
            font-size: 1.4rem;
            color: rgba(variables.$dark-gray-text, 0.87);
            word-break: break-word;
          }

          &.row-hover {
            cursor: pointer;

            &:hover {
              background-color: mat.get-color-from-palette(variables.$light-gray-palette, 50);
            }
          }
        }
      }
    }
  }
}

